import { observer } from "mobx-react";
import useStore from "../../../../hooks/useStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FORM_FIELDS,
  FREQUENCY_CONSTANTS,
  STATIC_GROUPS,
  WATER_UNITS_1,
} from "../../../../constants/constants";
import { Accordion, Form } from "react-bootstrap";
import FormDropdown from "../../../../components/formDropdown";
import GlobalUnitDropDown from "../../../../components/globalUnitDropDown";
import RecordExists from "../../../../components/recordExists";
import { useAuth } from "../../../../context/authProvider";
import { generatePayload } from "../../../../utils/helpers";
import { toJS } from "mobx";
import TableBody from "./tableBody";

const AddWaterPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    formData,
    groupsFilled,
    freq,
    formid,
    unitGlobal,
    skipped: tempSkipped,
  } = location.state || {};
  const {
    userStore: { userData },
    formStore: {
      fetchFields,
      fields,
      create,
      recordExists,
      formExists,
      setFormExists,
    },
    companyStore: { fetchUnits, companyUnit, kpiId, subCatId, catId, fetchFinancialData },
  } = useStore();

  const { showAlert } = useAuth();
  const [skipped, setSkipped] = useState(tempSkipped || false);

  const [groupPayload, setGroupPayload] = useState([]);
  const [validate, setValidate] = useState(false);
  const [editForm, setEditForm] = useState("");
  const [expanded, setExpanded] = useState("A");
  const [frequency, setFrequency] = useState({
    ...FREQUENCY_CONSTANTS,
    ...freq,
  });
  const [globalUnit, setGlobalUnit] = useState(unitGlobal || "");

  const tempGroupPayload = useMemo(
    () =>
      formData ||
      Object.entries(
        fields?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.group[0]]: [
              ...(acc[curr.group[0]] ? acc[curr.group[0]] : []),
              { ...curr, ...FREQUENCY_CONSTANTS, ...FORM_FIELDS },
            ],
          }),
          {}
        )
      ).map(([key, value]) => ({
        group: key,
        value: value.sort((a, b) => Number(a.position) - Number(b.position)),
        label: STATIC_GROUPS.water[key],
      })),
    [fields, formData]
  );

  useEffect(() => {
    setGroupPayload(tempGroupPayload);
  }, [tempGroupPayload]);

  useEffect(() => {
    setGroupPayload((prev) =>
      prev?.map((ele) => ({
        ...ele,
        value: ele?.value?.map((e) => ({ ...e, ...frequency })),
      }))
    );
  }, [frequency]);

  useEffect(() => {
    fetchFields();
    fetchUnits();
  }, [fetchFields, fetchUnits]);
  
  useEffect(() => {
      fetchFinancialData({
        payloadData: {
          kpiId,
          subCatId,
          catId,
          year:
          frequency.frequencyYear ||
          new Date(frequency.frequencyDate).getFullYear(),
          type: "water",
        }
      
      });
  }, [catId, fetchFinancialData, kpiId, subCatId, frequency.frequencyYear, frequency.frequencyDate]);

  const dropdownValidated = useMemo(
    () =>
      (() => (frequency.frequencyType === "Daily" ? 2 : 3))() ===
      Object.values(frequency).filter((e) => e)?.length,
    [frequency]
  );

  useEffect(() => {
    if (dropdownValidated & !formid) {
      recordExists({ frequency, type: "water" });
    }
  }, [dropdownValidated, formid, frequency, recordExists]);

  useEffect(
    () => () => {
      setFormExists();
    },
    []
  );

  const allGroups = useMemo(
    () => Array.from(new Set(tempGroupPayload?.map(({ group }) => group))),
    [tempGroupPayload]
  );

  useEffect(() => {
    if (groupsFilled?.length > 0) {
      setExpanded(allGroups[groupsFilled?.length]);
    }
  }, [allGroups, groupsFilled]);

  const payload = useCallback(
    ({ data, frequency, catId, companyUnit, kpiId, subCatId, userData }) =>
      generatePayload({
        data,
        companyUnit,
        kpiId,
        subCatId,
        catId,
        companyId: Number(toJS(userData)?.companyId),
      }).map((ele) => ({ ...ele, ...frequency })),
    []
  );

  const generateGroupPayload = useCallback(
    ({ data, frequency, catId, companyUnit, kpiId, subCatId }) => {
      return Object.entries(
        data.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.group]: [...(acc[curr.group] || []), curr],
          }),
          []
        )
      ).map(([key, values]) => {
        return {
          kpiId,
          subCatId,
          catId,
          targetValue: values.reduce((a, c) => a + (Number(c.target) || 0), 0),
          groupName: key,
          prevValue: values.reduce((a, c) => a + (Number(c.prevValue) || 0), 0),
          companyUnit: companyUnit,
          unitId: values.reduce((a, c) => a + (Number(c.unit) || 0), 0),
          ...frequency,
        }
      });
    },
    []
  );

  return (
    <div className="add-water-page">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">Add Water</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="/company/energy">Water List</Link>
                </li>
                <li className="breadcrumb-item active">Add Water</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="card p-4">
        <Form validated={validate}>
          <FormDropdown
            frequency={frequency}
            setFrequency={setFrequency}
            isDisabled={groupsFilled?.length > 0}
          />
          <GlobalUnitDropDown
            setData={setGroupPayload}
            filterUnit={WATER_UNITS_1}
            global={globalUnit}
            setGlobal={setGlobalUnit}
            disabled={!!unitGlobal}
          />
        </Form>
        {formExists ? (
          <RecordExists />
        ) : (
          <Accordion
            className="water-table-accordion"
            defaultActiveKey="A"
            activeKey={expanded}
            onSelect={(eventKey) => {
              setValidate(false);
              setExpanded(eventKey);
            }}
          >
            <div className="table-responsive ">
              {groupPayload?.map((data, index) => (
                <Accordion.Item
                  key={`${data.group}-accordion-item`}
                  eventKey={data.group}
                >
                  <Accordion.Header>
                    <tr style={{ display: "flex", gap: "4px" }}>
                      <td>{index + 1}</td>
                      <td colSpan={7}>{data.label}</td>
                    </tr>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Form
                      noValidate
                      validated={validate}
                      onSubmit={(event) => {
                        event.preventDefault();
                        const form = event.target;
                        if (form.checkValidity() && dropdownValidated) {
                          create({
                            payload: payload({
                              data: data.value,
                              frequency,
                              catId,
                              companyUnit,
                              kpiId,
                              subCatId,
                              userData,
                            }),
                            groupPayload: generateGroupPayload({
                              data: data.value,
                              frequency,
                              catId,
                              companyUnit,
                              kpiId,
                              subCatId,
                            }),
                            showAlert,
                            setEditForm,
                            navigate,
                            update: groupsFilled?.includes(data.group),
                            formId: formid,
                            totalGroups: groupPayload.length,
                            frequency,
                            groupData: groupPayload,
                            endpoint: "add-water",
                            redirectUrl: "/company/water",
                            pageUrl: "/company/add-water",
                            globalUnit,
                            group: data.group,
                            filled: groupsFilled,
                          });
                        } else {
                          showAlert("Please fill All fields.");
                        }
                        setValidate(true);
                      }}
                    >
                      <table className="table align-middle table-bordered table-nowrap mb-0">
                        <thead>
                          <tr key={12} className="waterTabletr">
                            <th key={0}>Sr. No.</th>
                            <th key={1}>Title</th>
                            <th key={2}>Unit</th>
                            <th key={3}>Frequency</th>
                            <th key={4}>Current Value</th>
                            {data.group !== "D" && (
                              <>
                                <th key={5}>Target Value</th>
                                <th key={6}>Comment</th>
                                <th key={8}>Attachment</th>
                              </>
                            )}
                          </tr>
                        </thead>
                        <TableBody
                          data={data}
                          setData={setGroupPayload}
                          frequency={frequency}
                          setEditForm={setEditForm}
                          editForm={editForm}
                          allGroups={allGroups}
                          groupData={groupPayload}
                          globalUnit={globalUnit}
                          setSkipped={setSkipped}
                          skipped={skipped}
                        />
                      </table>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </div>
          </Accordion>
        )}
      </div>
    </div>
  );
};

export default observer(AddWaterPage);
