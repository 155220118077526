import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import useStore from "../../hooks/useStore";
import DashboardWater from "../../components/dashboard/dashboardWater";
import DashboardWaste from "../../components/dashboard/dashboardWaste";
import DashboardEnergy from "../../components/dashboard/dashboardEnergy";
import DashboardAqi from "../../components/dashboard/dashboardAqi";
import DashboardBiodiversity from "../../components/dashboard/dashboardBiodiversity";
import { generateYearArray } from "../../utils/helpers";
import {
  GOVERNANCE_STATIC_GROUPS,
  GOVERNANCE_KPI_DASHBOARD,
} from "../../constants/constants";
import ColumnChart from "../../components/charts/columnChart";
import DonutChart from "../../components/charts/donutChart";
import PieChart from "../../components/charts/pieChart";
import { useMemo } from "react";
import { toJS } from "mobx";

import { filterData } from "../../helpers/groupDataCalculations";
import {
  CustomerAndPrivacyChart,
  CustomerHealthAndSafetyChart,
  DiversityAndEqualOpportunityChart,
  EmployeeHireGraph,
  MarketAndLabellingChart,
  NonDiscriminationChart,
  OhcChart,
  SupplierSocialAssesmentChart,
  TrainingAndEducationGraph,
} from "./socialCharts";

const EnvironmentDashboardTab = observer(() => {
  const {
    dashboardStore: { fetchDashboardDetails, showAll },
    companyStore: {
      fetchFinancialData,
      getPerformance,
      fetchRevenueData,
      revenuePageData,
      companyData,
      companyUnit,
    },
  } = useStore();
  const company = toJS(companyData);
  const assignCategory =
    company?.assignCategory.map(({ catId }) => catId) || [];
  const category =
    company?.assignCategory.map(({ catIdAssignCategory: { name } }) => name) ||
    [];

  const assignSubCategory =
    company?.assignSubCategory
      .filter(({ catId }) => assignCategory.includes(catId))
      .map(({ subCatId }) => subCatId) || [];

  const assignKpi =
    company?.assignKpi
      .filter(({ catId }) => assignCategory.includes(catId))
      .filter(({ name }) => category.includes("Environment"))
      .filter(({ subCatId }) => assignSubCategory.includes(subCatId))
      .map(({ kpiIdAssignKpi: { name } }) => name) || [];

  const [year, setYear] = useState(new Date().getFullYear());
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchFinancialData({ year });
    getPerformance({ year });
    fetchDashboardDetails({ signal, year, companyUnit });
    fetchRevenueData({});
    return () => {
      abortController.abort();
    };
  }, [
    fetchDashboardDetails,
    fetchFinancialData,
    fetchRevenueData,
    getPerformance,
    year,
    companyUnit,
    showAll,
  ]);

  const chartData = useMemo(() => {
    return ["waste", "all"].map((ele) => {
      const filteredData = revenuePageData?.revenue?.filter(
        (e) => e?.type === ele
      );
      const unit = filteredData?.[0]?.unit?.shortName || ""; // Assuming the unit is consistent within each type
      return {
        name: ele === "waste" ? "Revenue due to Waste" : "Total Revenue",
        unit,
        data: [year - 1, year].map((f) => {
          return (
            filteredData?.find((e) => f.toString() === e?.year?.toString())
              ?.value || 0
          );
        }),
      };
    });
  }, [revenuePageData, year]);

  // console.log("==========>",company.companiesData[0]?.role);
  return (
    <>
      <div className="d-flex justify-content-end ">
        <div className="w-25 dashboard-filter mb-4  d-flex gap-3 align-items-center ">
          <label className="form-label fs-6 ">Year</label>

          <select
            value={year}
            onChange={(event) => setYear(event.target.value)}
            className="form-select bg-white "
          >
            {generateYearArray(5).map((y) => (
              <option value={y}>{`FY ${y}-${y + 1}`}</option>
            ))}
          </select>
        </div>
      </div>
      {category.includes("Environment") && (
        <>
          {assignKpi.includes("Water") && (
            <div>
              <DashboardWater key="water-dashboard" year={year} />
            </div>
          )}
          {assignKpi.includes("Waste") && (
            <div>
              {/* <h3>Waste</h3> */}
              <DashboardWaste key="waste-dashboard" year={year} />
            </div>
          )}
          {assignKpi.includes("Energy") && (
            <div>
              {/* <h3>Energy</h3> */}
              <DashboardEnergy key="energy-dashboard" year={year} />
            </div>
          )}
          {assignKpi.includes("Emission") && (
            <div>
              {/* <h3>Emission</h3> */}
              <DashboardAqi key="aqi-dashboard" year={year} />
            </div>
          )}
          {assignKpi.includes("BIODIVERSITY") && (
            <div>
              {/* <h3>Biodiversity</h3> */}
              <DashboardBiodiversity key="biodiversity-dashboard" year={year} />
            </div>
          )}
          {company.companiesData[0]?.role !== "employee" && (
            <div className="row h-100">
              <div className="col-xl-12">
                <div className="card h-100 dashboard-card">
                  <div className="card-body">
                    <div className="d-flex flex-wrap align-items-start">
                      <h5 className="mb-3 text-muted"> Revenue Report</h5>
                      <div className="ms-auto">
                        <div className="toolbar d-flex flex-wrap gap-2 ">
                          <div className="">
                            <p className="text-muted mb-1">Unit</p>
                            <h5 className="unit-value">
                              {chartData.length > 0 ? chartData[0].unit : " "}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ColumnChart
                      data={chartData}
                      categories={[
                        `FY${(year - 1).toString().slice(2)}`,
                        `FY${year.toString().slice(2)}`,
                      ]}
                      yaxis={[]}
                      unit={chartData.length > 0 ? chartData[0].unit : " "}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
});

const GovernanceDashboardTab = observer(() => {
  const {
    governance: { fetchDashboard, dashboard },
    companyStore: { companyUnit },
    dashboardStore: { showAll },
  } = useStore();
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    fetchDashboard({ year, companyUnit });
  }, [fetchDashboard, year, companyUnit, showAll]);

  const specificFieldsForPieChart = useMemo(
    () => [
      "Existence and effectiveness of a risk management framework.",
      "Existence of a whistleblower protection policy.",
      "Voting results of key shareholder resolutions.",
      "Timeliness and accuracy of financial and non-financial reporting.",
      "Compliance with regulatory reporting requirements.",
      "Frequency of ESG-related disclosures and updates.",
      "Frequency of shareholder engagement meetings.",
      "Existence of a documented corporate governance framework.",
      "Compliance with international governance standards and best practices.",
      "Existence of data privacy and cybersecurity policies.",
    ],
    []
  );

  const getColumnChartData = useCallback(
    ({ group, year }) => {
      const current = dashboard?.[Number(year).toString()]?.[group] || [];
      const previous =
        dashboard?.[(Number(year) - 1).toString()]?.[group] || [];
      const toBeUsed = current?.length <= previous?.length ? previous : current;
      const result = toBeUsed
        ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName)) // Filter out specific fields
        ?.map((ele) => {
          return {
            name: ele.fieldName,
            data: [
              Number(previous.find((e) => e.fieldId === ele.fieldId)?.value) ||
                0,
              Number(current.find((e) => e.fieldId === ele.fieldId)?.value) ||
                0,
            ],
          };
        });
      return result || [];
    },
    [dashboard, specificFieldsForPieChart]
  );

  const getDonutChartData = useCallback(
    ({ group, year }) => {
      const current = dashboard?.[Number(year).toString()]?.[group] || [];
      const data =
        current
          ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName)) // Filter out specific fields
          ?.map((ele) => Number(ele.value) || 0) || [];
      const labels =
        current
          ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName)) // Filter out specific fields
          ?.map((ele) => ele.fieldName) || [];
      return { data, labels };
    },
    [dashboard, specificFieldsForPieChart]
  );
  const getPieChartData = useCallback(
    ({ group, year }) => {
      const current = dashboard?.[Number(year).toString()]?.[group] || [];
      const filteredData =
        current
          ?.filter((ele) => specificFieldsForPieChart.includes(ele.fieldName))
          ?.map((ele) => ({
            label: ele.fieldName,
            value:
              ele.value === "Yes"
                ? 100
                : ele.value === "No"
                ? 90
                : Number(ele.value),
          })) || [];

      const groupedData = filteredData.reduce((acc, { label, value }) => {
        if (!acc[label]) {
          acc[label] = [];
        }
        acc[label].push(value);
        return acc;
      }, {});

      return groupedData;
    },
    [dashboard, specificFieldsForPieChart]
  );

  return (
    <div>
      <div className="d-flex justify-content-end">
        <div className=" w-25 dashboard-filter mb-4  d-flex gap-3 align-items-center">
          <label className="form-label fs-6 ">Year</label>
          <select
            value={year}
            onChange={(event) => setYear(event.target.value)}
            className="form-select bg-white"
          >
            {generateYearArray(5).map((y) => (
              <option value={y}>{`FY ${y}-${y + 1}`}</option>
            ))}
          </select>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {GOVERNANCE_KPI_DASHBOARD.map((group) => (
          <div className="row h-100 align-items-stretch" key={group}>
            {/* Render ColumnChart and DonutChart for groups other than "F" */}
            {group !== "F" && (
              <>
                <div className="col-xl-8">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-start">
                        <h5 className="mb-3 text-muted">
                          {GOVERNANCE_STATIC_GROUPS.GovernanceKpis[group]}
                        </h5>
                      </div>
                      <div>
                        <ColumnChart
                          data={getColumnChartData({ group, year })}
                          categories={[
                            `FY${(year - 1).toString().slice(2)}`,
                            `FY${year.toString().slice(2)}`,
                          ]}
                          max={null}
                          yaxis={[]}
                          unit={" "}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4">
                  <div className="card mb-0 h-100">
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-start">
                        <h5 className="mb-3 text-muted">
                          {GOVERNANCE_STATIC_GROUPS.GovernanceKpis[group]}
                        </h5>
                      </div>
                      <div className="donut">
                        <DonutChart
                          height={512}
                          data={getDonutChartData({ group, year }).data}
                          labels={getDonutChartData({ group, year }).labels}
                          unit={" "}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {Object.entries(getPieChartData({ group, year })).length > 0 && (
              <div className="w-100">
                <div className="mb-0 h-100 col-xl-12">
                  <div className="d-flex flex-wrap align-items-start">
                    {group === "F" && (
                      <h5 className="mb-3 text-muted">
                        {GOVERNANCE_STATIC_GROUPS.GovernanceKpis[group]}
                      </h5>
                    )}
                  </div>
                  <div className="mb-0 h-100 col-xl-12">
                    <div className="pie row">
                      {Object.entries(getPieChartData({ group, year })).map(
                        ([label, data], index) => {
                          // Map series values to corresponding labels
                          const labels = data.map((value) =>
                            value === 100 ? "Yes" : value === 90 ? "No" : ""
                          );

                          return (
                            <div className="col-xl-4 mt-3" key={index}>
                              <div className="card h-100">
                                <div className="card-body">
                                  <h6>{label}</h6>
                                  <PieChart
                                    height={380}
                                    series={data}
                                    labels={labels}
                                    unit={" "}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
});

const SocialDashboardTab = observer(() => {
  const {
    social: { fetchChartData, chartData },
    companyStore: { companyData, companyUnit },
    dashboardStore: { showAll },
  } = useStore();

  const company = toJS(companyData);
  const assignCategory =
    company?.assignCategory.map(({ catId }) => catId) || [];
  const category =
    company?.assignCategory.map(({ catIdAssignCategory: { name } }) => name) ||
    [];

  const assignSubCategory =
    company?.assignSubCategory
      .filter(({ catId }) => assignCategory.includes(catId))
      .map(({ subCatId }) => subCatId) || [];

  const assignKpi =
    company?.assignKpi
      .filter(({ catId }) => assignCategory.includes(catId))
      .filter(({ subCatId }) => assignSubCategory.includes(subCatId))
      .map(({ kpiIdAssignKpi: { name } }) => name) || [];
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    fetchChartData({ year, companyUnit });
  }, [fetchChartData, year, companyUnit, showAll]);
  const categories = [
    `FY${(year - 1).toString().slice(2)}`,
    `FY${year.toString().slice(2)}`,
  ];

  return (
    <div>
      <div className="d-flex justify-content-end">
        <div className=" w-25 dashboard-filter mb-4  d-flex gap-3 align-items-center">
          <label className="form-label fs-6 ">Year</label>
          <select
            value={year}
            onChange={(event) => setYear(event.target.value)}
            className="form-select bg-white"
          >
            {generateYearArray(5).map((y) => (
              <option value={y}>{`FY ${y}-${y + 1}`}</option>
            ))}
          </select>
        </div>
      </div>
      {category.includes("Social") && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            {assignKpi.includes("Employment") && (
              <EmployeeHireGraph
                categories={categories}
                data={chartData?.filter((e) => e.kpiName === "Employment")}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("OH&C") && (
              <OhcChart
                categories={categories}
                data={filterData(chartData, "kpiName", "OH&C")}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("Training and Education") && (
              <TrainingAndEducationGraph
                data={filterData(
                  chartData,
                  "kpiName",
                  "Training and Education"
                )}
                year={Number(year)}
                categories={categories}
              />
            )}
            {assignKpi.includes("Diversity And Equal Opportunity") && (
              <DiversityAndEqualOpportunityChart
                data={filterData(
                  chartData,
                  "kpiName",
                  "Diversity And Equal Opportunity"
                )}
                year={Number(year)}
                categories={categories}
              />
            )}
            {assignKpi.includes("Non-Discrimination") && (
              <NonDiscriminationChart
                data={filterData(chartData, "kpiName", "Non-Discrimination")}
                categories={categories}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("Supplier Social Assessment") && (
              <SupplierSocialAssesmentChart
                data={filterData(
                  chartData,
                  "kpiName",
                  "Supplier Social Assessment"
                )}
                categories={categories}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("Customer Health and Safety") && (
              <CustomerHealthAndSafetyChart
                data={filterData(
                  chartData,
                  "kpiName",
                  "Customer Health and Safety"
                )}
                categories={categories}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("Marketing and Labeling") && (
              <MarketAndLabellingChart
                data={filterData(
                  chartData,
                  "kpiName",
                  "Marketing and Labeling"
                )}
                categories={categories}
                year={Number(year)}
              />
            )}
            {assignKpi.includes("Customer Privacy") && (
              <CustomerAndPrivacyChart
                data={filterData(chartData, "kpiName", "Customer Privacy")}
                categories={categories}
                year={Number(year)}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
});

export { EnvironmentDashboardTab, GovernanceDashboardTab, SocialDashboardTab };
