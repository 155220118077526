// BarChart.js
import React from "react";
import Chart from "react-apexcharts";

const ColumnChart = ({
  data,
  categories,
  hover,
  title,
  yaxis,
  unit,
  height = "300%", //650 default
  max,
  columnWidth,
  ...props
}) => {
  const options = {
    chart: {
      type: "bar",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetY: "100%",
        offsetX: 0,
        tools: {
          download:
            '<i class="mdi mdi-download" aria-hidden="true" style="font-size: 30px;" ></i>',
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: columnWidth || 35, // earlier it was 20%
        endingShape: "rounded",
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },

      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
        transform: "rotate(145deg)",
      },
    },
    // colors: ["#556EE6", "#35C38F", "#F46A6A", "#F1B44C"],

    colors: [
      "#FCD163",
      "#EF8083",
      "#88BE53",
      "#46A1E1",
      "#A889C4",
      "#FFA07A",
      "#90EE90",
      "#87CEEB",
      "#DDA0DD",
    ],
    legend: {
      show: true,
      showForSingleSeries: true, // Add this line
      horizontalAlign: 'center',
      markers: {
        width: 12,
        height: 12,
        radius: 12,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 5,
      },
    },

    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
    },
    annotations: {
      yaxis,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${hover ? hover : ""} ${val} ${unit || "KL"}`;
        },
      },
      // fixed: {
      //   enabled: true,
      //   position: "middleRight",
      // },
    },
    yaxis: {
      ...(max && { max }),
      min: 0,
    },
    units: { unit },
  };
  const series = data || [];
  return (
    <div id="chart" className="writemode">
      <Chart
        options={options}
        series={series}
        type="bar"
        height={height}
        unit={unit}
        {...props}
      />
    </div>
  );
};

export default ColumnChart;
