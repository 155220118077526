import { observer } from "mobx-react";
import Editor from "./components/editor";
import React, { useEffect, useRef, useState } from "react";
import Tools from "./components/tools";
import ReactDOMServer from "react-dom/server";
import InitialContent from "./components/initialContent";
import fetchData from "../../utils/fetchData";

const Report = () => {
  const ref = useRef(null);
  const editor = ref.current;
  const [data, setData] = useState({});
  useEffect(() => {
    fetchData("company/initialContent", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          setData(res.data);
        }
      })
      .catch((err) => {
        console.error("[Error]", err);
      });
  }, []);

  const initialValue = ReactDOMServer.renderToStaticMarkup(
    <InitialContent data={data} />
  );
  return (
    <div className="card p-4">
      <h1>Reports for the table</h1>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          className="report-editor"
          style={{
            width: "60%",
            height: "calc(100vh - 300px)",
          }}
        >
          {data && <Editor ref={ref} initialValue={initialValue} />}
        </div>
        <Tools editor={editor} />
      </div>
    </div>
  );
};

export default observer(Report);
