import { observer } from "mobx-react";
import Charts from "./charts";

const Tools = ({ editor }) => {
  return (
    <div
      style={{
        width: "40%",
        padding: "10px",
      }}
    >
      <h3>Tools Container</h3>
      <Charts editor={editor} />
    </div>
  );
};

export default observer(Tools);
