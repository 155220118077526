import vision from "../../../assets/images/Report/vision.jpeg";
import values from "../../../assets/images/Report/values.jpeg";
import mission from "../../../assets/images/Report/mission.jpeg";
import img10 from "../../../assets/images/Report/10.jpeg";
import env from "../../../assets/images/Report/Env.jpeg";
import social from "../../../assets/images/Report/Social.jpeg";
import gov from "../../../assets/images/Report/Gov.jpeg";
import envDay from "../../../assets/images/Report/env-day.png";
import pyramid from "../../../assets/images/Report/pyramid.png";
import climateSmart from "../../../assets/images/Report/ClimateSmart.png";
import envEff from "../../../assets/images/Report/env-eff.png";
import solar from "../../../assets/images/Report/solar.png";
import reuse from "../../../assets/images/Report/reuse.png";
import handWash from "../../../assets/images/Report/hand-wash.png";
import waste from "../../../assets/images/Report/waste.png";
import scope1 from "../../../assets/images/Report/scope1.png";
import numToRoman from "../../../helpers/numToRoman";

const getConverter = (data) => (keys) =>
  keys?.map((val, index) => {
    const curr = data?.waste?.curr?.find((e) => e.name === val)?.value;
    const prev = data?.waste?.prev?.find((e) => e.name === val)?.value;
    return {
      curr,
      prev,
      name: val,
      id: index,
    };
  });

const InitialContent = ({ data }) => {
  const year = new Date().getFullYear();
  const performance = data?.performance;

  const convertData = getConverter(data);

  const typesOfWasteGenerated = convertData([
    "Hazardous",
    "Non-hazardous",
    "E-waste",
    "Plastic waste",
    "Bio-medical waste",
    "Construction and demolition waste",
    "Battery waste",
    "Radioactive waste",
    "Other",
  ]);

  const hazardous = convertData([
    "Hazardous waste - Reuse",
    "Hazardous waste - Composting",
    "Hazardous waste - Recycling",
    "Hazardous waste - other",
  ]);

  const nonHazardous = convertData([
    "Non-hazardous - Reuse",
    "Non-hazardous - Composting",
    "Non-hazardous - Recycling ",
    "Non-hazardous - other ",
  ]);

  const eWaste = convertData(["E-waste - Recycle"]);

  const hazWaste = convertData([
    "Hazardous waste - Incineration With Energy recovery",
    "Hazardous waste -  Incineration Without Recovery",
    "Hazardous waste -  Landfilling",
    "Hazardous waste -  Co-processing",
    "Hazardous waste - Deep well injection",
    "Hazardous waste - Other",
  ]);

  const nonHazWaste = convertData([
    "Non-Hazardous - Incineration With Energy recovery ",
    "Non-Hazardous -  Incineration Without Recovery",
    "Non-Hazardous -  Landfilling",
    "Non-Hazardous -  Deep well injection",
    "Non-Hazardous -  Other",
  ]);

  return (
    <div>
      <div className="">
        <h2>About the Report </h2>
        <p>free text</p>

        <div style={{ display: "flex", gap: "30px" }}>
          <div style={{ border: "1px solid black", padding: "10px" }}>
            <h3>Frameworks and Standards for Reporting </h3>
            <p>Free text</p>
          </div>

          <div style={{ border: "1px solid black", padding: "10px" }}>
            <h3>Scope and Reporting Limits</h3>
            <p>free text</p>
          </div>

          <div style={{ border: "1px solid black", padding: "10px" }}>
            <h3>Feedback </h3>
            <p>
              We are committed to gathering feedback on our sustainability
              performance disclosures from all our stakeholders. Please feel
              free to share your feedback, suggestions, or questions at: Company
              Mail ID{" "}
            </p>
          </div>

          <div style={{ border: "1px solid black", padding: "10px" }}>
            <h3>"Third-Party Validation" </h3>
            <p>
              The Sustainability Report for FY23 has been independently verified
              by CETIZION Verifica Pvt. Ltd.{" "}
            </p>
          </div>
        </div>

        <img style={{ width: "100%" }} src="" alt="" />
      </div>

      <div>
        <h2> ESG Performance Highlight </h2>

        <div style={{ display: "flex" }}>
          <div style={{ border: "1px solid black" }}>
            <h2
              style={{
                textOrientation: "upright",
                writingMode: "vertical-rl",
              }}
            >
              Environment
            </h2>
          </div>
          <img style={{ width: "500px" }} src={env} alt="" />

          <p>Free text</p>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ border: "1px solid black" }}>
            <h2
              style={{
                textOrientation: "upright",
                writingMode: "vertical-rl",
              }}
            >
              Social
            </h2>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p>Free text</p>
            <img style={{ width: "500px" }} src={social} alt="" />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ border: "1px solid black" }}>
            <h2
              style={{
                textOrientation: "upright",
                writingMode: "vertical-rl",
              }}
            >
              Governance
            </h2>
          </div>
          <img style={{ width: "500px" }} src={gov} alt="" />
          <p className="">Free text</p>
        </div>
      </div>

      <div>
        <h2>Message from the Managing Director</h2>

        <div
          style={{
            display: "flex",
            margin: "0 auto",
            border: "1px solid black",
            borderRadius: "50%",
            overflow: "hidden",
            width: "400px",
            height: "300px",
          }}
        >
          <img
            style={{ width: "100%", height: "100%" }}
            src="https://www.shutterstock.com/image-illustration/mockup-3d-logo-facade-sign-600nw-2046330740.jpg"
            alt="MD PIC"
          />
        </div>

        <div>
          <ul>
            <h3 htmlFor="">Dear Stake Holders </h3>
            <li>
              Globally, businesses have the potential to significantly
              contribute to building a more sustainable and inclusive society.
              At our organization, we are committed to leveraging the strength,
              expertise, and network of our people to achieve sustainable
              outcomes for all our stakeholders. Our purpose is driven by our
              Vision: ‘Reaching People and Touching Lives Globally as a Leading
              Provider of Valued Medicines.’{" "}
            </li>

            <li>
              We firmly believe that the health of our planet is deeply
              connected to the life it sustains, which is essential for global
              societal progress. In a world marked by increasing uncertainty, we
              are dedicated to collaborating and co-creating solutions with our
              stakeholders that have a positive impact on society.{" "}
            </li>

            <li>
              Our efforts are guided by a well-defined roadmap for our
              Environment, Social, and Governance (ESG) initiatives. I am
              pleased to share that our organization has become a signatory of
              the United Nations Global Compact (UNGC), one of the world’s
              largest corporate sustainability initiatives. This further
              underscores our commitment to aligning our strategy with universal
              ESG principles and taking the necessary steps to advance societal
              goals.{" "}
            </li>

            <li>
              In addition to embedding ESG priorities into our business strategy
              and operations, we recognize the importance of transparent and
              accurate reporting on our progress and future sustainability
              targets. I am proud to present our Sustainability Report for FY24,
              which offers valuable insights into our sustainability journey,
              our key focus areas, our accomplishments, and our long-term goals.{" "}
            </li>
          </ul>
        </div>

        <div>
          <h3>Environmental Responsibility and Climate Action </h3>
          <p>free text</p>
        </div>

        <div>
          <h3>Employee Well-being </h3>
          <p>
            Our people are the core of our success. As a global organization, we
            take pride in attracting and retaining a diverse, skilled workforce
            that drives innovation and synergy. We invest in employee growth
            through training, recognition, and engagement opportunities, and
            have earned Great Place to Work Certification in FY24 in India for
            our people practices.{" "}
          </p>

          <p>
            Employee safety is a top priority, with comprehensive health and
            safety training across all locations and adherence to our
            Environment, Health, and Safety (EHS) Policy. Our ISO 45001:2018
            certification reinforces our commitment to workplace safety.{" "}
          </p>

          <p>
            We foster an environment that supports growth, well-being, and
            safety, paving the way for a sustainable future.{" "}
          </p>
        </div>

        <div>
          <h3>Social Impact and Community Development</h3>

          <p>
            Our organization plays a key role in fostering an inclusive society
            by leveraging resources to support underprivileged communities. We
            focus on healthcare, education, water and sanitation, environmental
            conservation, rural development, and disaster relief.{" "}
          </p>

          <p>
            In FYXX, we invested XXXX Million in Corporate Social Responsibility
            (CSR). We are committed to advancing the United Nations Sustainable
            Development Goals (UN SDGs) through our socially responsible
            programs.{" "}
          </p>
        </div>

        <div>
          <h3>Enhancing Healthcare Accessibility</h3>
          <p>
            Access to healthcare is a fundamental right, and we are dedicated to
            providing high-quality medicines at affordable prices. With XX
            manufacturing facilities and a presence in over XX countries, we
            address unmet patient needs. In FYXX, we invested ₹XX Billion in R&D
            to drive innovation. We actively seek collaborations and engage with
            healthcare providers to enhance patient outcomes and work towards a
            healthier, more inclusive future.
          </p>
        </div>

        <div>
          <h3>Maintaining Commitment to Product Safety and Quality</h3>

          <p>
            As a leading pharmaceutical manufacturer, we prioritize product
            quality and safety throughout their lifecycle. Our facilities are
            approved by major regulatory agencies, and we hold ISO 9001:2015
            certification at many sites. We use a proactive approach to risk
            management and quality assurance, supported by a strong Quality
            Management System. Our global drug safety monitoring team ensures
            product safety and effectiveness, and our quality practices extend
            to our supply chain through our Global Code of Conduct and updated
            vendor audits that now include ESG parameters.{" "}
          </p>
        </div>

        <div>
          <h3>"Planning for the Future"</h3>
          <p>
            Our ESG strategy's success hinges on continuous improvement and
            increased focus on climate resilience and responsible operations. We
            will integrate ESG priorities into our long-term strategy, actively
            engage with stakeholders, and collaborate with experts to drive
            positive change. By fostering innovation and responsibility, we aim
            to build a sustainable future. We thank our employees, customers,
            partners, and shareholders for their unwavering support as we work
            towards a brighter, healthier future for all.{" "}
          </p>
        </div>

        <div>
          <p>XXXXXXXXXXXXXX Name of MD with Digital Signature</p>

          <p>Managing Director</p>
          <p>XXXXXXXXXXXXXXXXXXXXX Company Details</p>
        </div>
      </div>

      <div>
        <h2>About the Company</h2>

        <img src="h" alt="about the company" />
        <h3>Enhancing Lives Every Day </h3>
        <p>free text</p>

        <h3>Our Global Presence</h3>
        <img src="" alt="world map" />
      </div>

      <div style={{ display: "flex", gap: "16px" }}>
        <div style={{ width: "33%", border: "1px solid black" }}>
          <img style={{ width: "100%" }} src={vision} alt="" />
          <p>
            Vision of Leadership: The organization aspires to be a leader in its
            field, setting the standard for others to follow. This leadership
            isn't just about market dominance; it's about being at the forefront
            of positive change, particularly in promoting health and well-being.{" "}
          </p>
          <p>
            Global Impact: The organization's focus is worldwide. It aims to
            have a broad, international presence and impact, indicating that its
            products and services are meant to benefit people across different
            countries and cultures.{" "}
          </p>

          <p>
            Commitment to Quality: Quality is a central theme. The organization
            prioritizes delivering top-quality products, which is crucial for
            earning trust and ensuring effectiveness, especially in sectors like
            pharmaceuticals or healthcare.{" "}
          </p>

          <p>
            Innovation: The organization is dedicated to pioneering new and
            innovative solutions. This suggests a forward-thinking approach,
            where continuous improvement and adaptation are key strategies.
            Innovation isn't just about new products; it also involves improving
            existing ones and finding better ways to meet global needs.{" "}
          </p>

          <p>
            Positive Impact on Well-Being: The ultimate goal is to improve the
            well-being of individuals and communities. This reflects a
            commitment to corporate social responsibility, where the
            organization's success is measured not just by profit but by the
            positive impact it has on people's lives.{" "}
          </p>

          <p>
            Consistency in Delivery: There's an emphasis on consistently
            delivering excellence. This indicates that the organization aims to
            maintain high standards over time, ensuring that its contributions
            to global health are reliable and ongoing.{" "}
          </p>
        </div>
        <div style={{ width: "33%", border: "1px solid black" }}>
          <img style={{ width: "100%" }} src={mission} alt="" />
          <p>
            Our mission is to build a profitable and sustainable organization
            that consistently delivers excellence across global markets. We aim
            to offer a diverse range of high-quality products, ensuring that our
            operations are system-driven and aligned with world-class standards.
          </p>
          <p>
            To achieve this, we rely on the strength of our loyal customer base,
            the dedication of our talented team, and the implementation of
            effective systems. By fostering a culture of innovation and
            continuous improvement, we leverage the latest technologies to stay
            at the forefront of our industry.
          </p>

          <p>
            Through these combined efforts, we are committed to elevating
            industry standards and making a positive impact on the lives of
            individuals worldwide, contributing to a better future for all.
          </p>
        </div>
        <div style={{ width: "33%", border: "1px solid black" }}>
          <img style={{ width: "100%" }} src={values} alt="" />
          <p>
            Our organizational culture is grounded in core values that ensure
            equal opportunity for everyone to contribute toward achieving both
            organizational and societal goals.
          </p>
          <p>
            We view our customers as our most valuable teachers, and our
            employees as our most important assets, recognizing their critical
            roles in our success.
          </p>

          <p>
            Decision-making within our organization is guided by a clear and
            defined value system, which we refer to as the ‘RIPCO’ Value System.
          </p>
          <p>
            This value-driven approach helps us align our actions with our
            mission, ensuring consistency and integrity in all that we do
          </p>
        </div>
      </div>

      <div>
        <h3>Financial Performance</h3>
        <h2>Achieving Consistent Profitable Growth</h2>
        <p>
          We aim to strengthen our core competencies to serve global communities
          with our portfolio of value-added products, driving sustained health
          outcomes. To deliver longterm value to our shareholders and
          stakeholders, we are dedicated to optimizing efficiency, cash flow,
          and cost structure while expanding our global business.
        </p>
      </div>

      <div>
        <h4>Corporate Governance</h4>
        <h3>Exceeding Regulatory Standards.</h3>
        <p>
          Our corporate governance approach is deeply rooted in our values of
          quality, reliability, trust, consistency, and innovation. Upholding
          the highest standards of ethical business conduct is crucial to
          creating shared value for all stakeholders. By maintaining ethical
          practices, we ensure transparent and accountable communication, which
          supports responsible and sustainable decision-making.
        </p>

        <div>
          <h2>International Code of Conduct</h2>
          <p>
            Our International Code of Conduct is the cornerstone of our strong
            corporate governance framework. Aligned with our core values, the
            International Code of Conduct offers essential guidance on
            maintaining the highest standards of responsible behavior throughout
            our business operations and value chain. It serves as the governing
            document for all business activities and applies to all employees
            and the Board of Directors. Additionally, all business partners,
            including suppliers, service providers, agents, and channel
            partners, are required to adhere to the principles outlined in the
            International Code of Conduct.
          </p>

          <img style={{ width: "100%" }} src={img10} alt="" />
        </div>
      </div>

      {/* about section ends  */}

      <div>
        <h2>Environmental Stewardship</h2>
        <h3>A Commitment to Safeguard Our Planet.</h3>
        <div style={{ display: "flex", gap: "10px" }}>
          <div style={{ border: "1px solid black" }}>
            <ul>
              <li>
                Protecting the environment is central to our efforts,
                representing a key strategic priority and a fundamental aspect
                of our sustainable development goals. Aware of our
                responsibility to minimize any harmful impacts on the
                environment, we implement targeted actions to lessen our
                ecological footprint.
              </li>
            </ul>
          </div>
          <div style={{ border: "1px solid black" }}>
            <ul>
              <li>
                {" "}
                As a responsible corporate entity, we acknowledge our role in
                supporting the United Nations' Sustainable Development Goals (UN
                SDGs). We prioritize the efficient use of resources and use our
                influence within the business value chain to help create a more
                sustainable future.
              </li>
            </ul>
          </div>
        </div>

        <img style={{ width: "100%" }} src={envDay} alt="" />
      </div>

      <div>
        <h2>ENVIRONMENT Performance 2024</h2>
        <p>
          We have set ambitious goals to make a positive environmental impact
          and have implemented responsible actions and thoughtful practices to
          achieve these goals effectively.
        </p>

        <div>
          <h3 style={{ textAlign: "center", color: "green" }}>
            Energy Management
          </h3>
          <table style={{ width: "100%", border: "1px solid black" }}>
            <thead>
              <tr>
                <th>Highlights</th>
                <th>Achievement</th>
                <th>Target</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Highlight" && e?.type === "energy"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) =>
                          e?.title === "Achievements" && e?.type === "energy"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Target" && e?.type === "energy"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h3 style={{ textAlign: "center", color: "green" }}>
            Waste Management
          </h3>
          <table style={{ width: "100%", border: "1px solid black" }}>
            <thead>
              <tr>
                <th>Highlights</th>
                <th>Achievement</th>
                <th>Target</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Highlight" && e?.type === "waste"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) =>
                          e?.title === "Achievements" && e?.type === "waste"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Target" && e?.type === "waste"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h3 style={{ textAlign: "center", color: "green" }}>
            Water Management
          </h3>
          <table style={{ width: "100%", border: "1px solid black" }}>
            <thead>
              <tr>
                <th>Highlights</th>
                <th>Achievement</th>
                <th>Target</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Highlight" && e?.type === "water"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) =>
                          e?.title === "Achievements" && e?.type === "water"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Target" && e?.type === "water"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h3 style={{ textAlign: "center", color: "green" }}>
            Carbon Emissions
          </h3>
          <table style={{ width: "100%", border: "1px solid black" }}>
            <thead>
              <tr>
                <th>Highlights</th>
                <th>Achievement</th>
                <th>Target</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Highlight" && e?.type === "aqi"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Achievements" && e?.type === "aqi"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {performance
                      ?.filter(
                        (e) => e?.title === "Target" && e?.type === "aqi"
                      )
                      ?.map((e) => (
                        <li>{e?.value}</li>
                      ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <h3>Environmental Governance Mechanism</h3>

        <ul>
          <li>
            Our Environmental Governance Mechanism encompasses a robust
            Environment, Health, and Safety (EHS) policy, alongside a
            well-defined EHS Management System and Energy Management System.
            These frameworks are crucial for supporting our efforts and ensuring
            the effective achievement of our environmentally responsible
            objectives, particularly in managing greenhouse gas emissions, water
            consumption, and waste generation.
          </li>
          <li>
            Our EHS governance processes play a critical role in evaluating the
            effectiveness of our environmental initiatives and maintaining
            oversight of EHS performance relative to our targets. We conduct
            regular internal and external audits, as well as inspections, to
            track and assess compliance with relevant EHS regulations and
            standards.
          </li>
          <li>
            Additionally, we have implemented a comprehensive emergency response
            plan across all our sites, ensuring rapid and efficient resolution
            of any EHS incidents or accidents. Our EHS Management System aligns
            with ISO 14001:2015 standards.
          </li>
          <li>
            Recognizing the critical role of our workforce in achieving our
            environmental goals, we have established various engagement
            platforms to raise awareness and promote environmentally friendly
            practices among employees.
          </li>
          <li>
            These forums empower our workforce to identify and address potential
            hazards within our operations, evaluate and mitigate environmental
            impacts, and advocate for a safe and healthy work environment. We
            also provide targeted EHS training to our employees, encouraging
            responsible conduct and environmentally conscious behavior.
          </li>
        </ul>

        <img
          style={{ width: "100%", textAlign: "center" }}
          src={pyramid}
          alt=""
        />
      </div>

      <div>
        <h2 style={{ textAlign: "center", color: "green" }}>
          Our Climate-smart Vision
        </h2>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid black",
        }}
      >
        <h4>Free text</h4>
        <img src={climateSmart} alt="" />
      </div>

      <div>
        <h2>Energy Managements</h2>
        <p>Energy Efficiency</p>
        <p>
          Our energy demand and fossil fuel-based energy consumption in our
          operations are inextricably linked to greenhouse gas emissions and
          influence the achievement of our decarburization targets. To reduce
          our overall energy demand and leverage clean energy for our
          operations, we have adopted a three-pronged approach of monitor,
          minimize, and decarbonize
        </p>

        <img src={envEff} style={{ width: "100%" }} alt="" />

        <h2>
          Graph of Energy managements from Tools with PII Charts Including Sub
          category
        </h2>
      </div>

      <div>
        <h2>Minimize and Decarbonize</h2>
        <p>
          In FY24, we have introduced targeted energy efficiency initiatives. A
          few of these are listed below:
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            border: "1px solid black",
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ border: "1px solid black", width: "100%" }}>
                <p>1 .Free Text.</p>
              </div>
              <div style={{ border: "1px solid black", width: "100%" }}>
                <p>2. Free Text</p>
              </div>
            </div>

            <div style={{ border: "1px solid black", height: "100px" }}>
              3. Free text
            </div>
          </div>
          <img src={solar} alt="" />
        </div>
      </div>

      <div>
        <h2>Water Managements</h2>

        <h3>
          Our water management strategy follows the principles of the 4Rs:
          'Reduce, Reuse, Recycle, and Recharge'.
        </h3>

        <img src={reuse} alt="" style={{ width: "100%" }} />

        <div style={{ border: "1px solid black" }}>
          <p style={{ textAlign: "center" }}>Free Texts</p>
        </div>
      </div>

      <div>
        <h2>Water withdrawal by sources:</h2>

        <div style={{ display: "flex" }}>
          <p>
            Should be taken from tool with PII charts & Pic to be adjust
            somewhere in page:
          </p>

          <img src={handWash} alt="" style={{ width: "100%" }} />
        </div>
      </div>

      <div style={{ border: "1px solid black", width: "100%" }}>
        <table border="1" width={"100%"}>
          <tbody>
            <tr>
              <td colspan="2">Water Consumption</td>
            </tr>
            <tr>
              <td>Water Discharge</td>
              <td>Water Intensity (KL/revenue in I Mn)</td>
            </tr>
            <tr>
              <td>Water withdrawal by water stress area </td>
              <td>Water Discharged in Water-stressed Areas</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <h2>Waste Managements:</h2>

        <h3>
          Waste management in an organization entails a comprehensive approach
          to managing waste efficiently and minimizing its environmental impact.
          This approach includes preventing waste through process optimization
          and reducing waste by improving operational efficiencies. The
          organization also focuses on reusing materials wherever possible and
          recycling items to transform them into new products. Additionally,
          waste recovery methods, such as waste-to-energy technologies and
          composting, are employed to extract value from waste. Proper disposal
          methods are used for non-recyclable and hazardous materials, ensuring
          compliance with regulations.
        </h3>

        <h3>
          To support these efforts, effective waste management practices include
          waste segregation at the source, regular waste audits, and
          comprehensive employee training. Collaboration with suppliers helps to
          reduce packaging waste and implement sustainable practices. Clear
          policies and goals are established to guide waste management efforts,
          while performance metrics and reporting maintain transparency and
          accountability. Continuous improvement practices are essential for
          refining and enhancing waste management strategies over time.
        </h3>

        <img src={waste} alt="" style={{ width: "100%" }} />
      </div>

      <div>
        <h2 style={{ color: "green" }}>Type of Waste Generated (MT)</h2>

        <table border="1" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr style={{ backgroundColor: "#f8e4a0" }}>
              <th></th>
              <th></th>
              <th>{`FY${year - 1}`}</th>
              <th>{`FY${year}`}</th>
            </tr>
          </thead>
          <tbody>
            {typesOfWasteGenerated?.map((e, index) => (
              <tr key={`types_of_waste_generated${e}`}>
                <td>{numToRoman(index + 1)}</td>
                <td>{e.name}</td>
                <td>{e.prev}</td>
                <td>{e.curr}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <h2
          style={{
            color: "green",
          }}
        >
          Waste Diverted from Disposal (MT)
        </h2>

        <table border="1" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr style={{ backgroundColor: "#f8e4a0" }}>
              <th>2</th>
              <th>Categories</th>
              <th>{`FY${year - 1}`}</th>
              <th>{`FY${year}`}</th>
            </tr>
          </thead>
          <tbody>
            {/* Hazardous waste */}
            <tr>
              <td>i</td>
              <td>
                <strong>Hazardous waste</strong>
              </td>
              <td></td>
              <td></td>
            </tr>
            {hazardous?.map((e) => (
              <tr key={e.name}>
                <td>*</td>
                <td>{e.name}</td>
                <td>{e.prev}</td>
                <td>{e.curr}</td>
              </tr>
            ))}
            <tr>
              <td
                colSpan="2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Total (i)
              </td>
              <td>{hazardous.reduce((acc, curr) => acc + curr.prev, 0)}</td>
              <td>{hazardous.reduce((acc, curr) => acc + curr.curr, 0)}</td>
            </tr>

            {/* Non-hazardous */}
            <tr>
              <td>ii</td>
              <td>
                <strong>Non-hazardous</strong>
              </td>
              <td></td>
              <td></td>
            </tr>
            {nonHazardous?.map((e) => (
              <tr key={e.name}>
                <td>*</td>
                <td>{e.name.split("-")[1]}</td>
                <td>{e.prev}</td>
                <td>{e.curr}</td>
              </tr>
            ))}
            <tr>
              <td
                colSpan="2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Total (ii)
              </td>
              <td>{nonHazardous.reduce((acc, curr) => acc + curr.prev, 0)}</td>
              <td>{nonHazardous.reduce((acc, curr) => acc + curr.curr, 0)}</td>
            </tr>

            {/* E-waste */}
            <tr>
              <td>iii</td>
              <td>
                <strong>E-waste</strong>
              </td>
              <td></td>
              <td></td>
            </tr>
            {eWaste?.map((e) => (
              <tr key={e.name}>
                <td>*</td>
                <td>{e.name.split("-")[1]}</td>
                <td>{e.prev}</td>
                <td>{e.curr}</td>
              </tr>
            ))}
            <tr>
              <td
                colSpan="2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Total (iii)
              </td>
              <td>{eWaste.reduce((acc, curr) => acc + curr.prev, 0)}</td>
              <td>{eWaste.reduce((acc, curr) => acc + curr.curr, 0)}</td>
            </tr>
          </tbody>
        </table>

        <h2 style={{ color: "green" }}>Waste Directed to Disposal (MT)</h2>

        <table border="1" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr style={{ backgroundColor: "#f8e4a0" }}>
              <th>3</th>
              <th>Categories</th>
              <th>{`FY${year - 1}`}</th>
              <th>{`FY${year}`}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>i</td>
              <td>
                <strong>Hazardous waste</strong>
              </td>
              <td></td>
              <td></td>
            </tr>
            {hazWaste?.map((e) => (
              <tr key={e.name}>
                <td>*</td>
                <td>{e.name.split("-")[1]}</td>
                <td>{e.prev}</td>
                <td>{e.curr}</td>
              </tr>
            ))}
            <tr>
              <td
                colSpan="2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Total (i)
              </td>
              <td>{hazWaste.reduce((acc, curr) => acc + curr.prev, 0)}</td>
              <td>{hazWaste.reduce((acc, curr) => acc + curr.curr, 0)}</td>
            </tr>

            {/* Non-Hazardous */}
            <tr>
              <td>ii</td>
              <td>
                <strong>Non-Hazardous</strong>
              </td>
              <td></td>
              <td></td>
            </tr>
            {nonHazWaste?.map((e) => (
              <tr key={e.name}>
                <td>*</td>
                <td>{e.name.split("-")[1]}</td>
                <td>{e.prev}</td>
                <td>{e.curr}</td>
              </tr>
            ))}
            <tr>
              <td
                colSpan="2"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Total (ii)
              </td>
              <td>{nonHazWaste.reduce((acc, curr) => acc + curr.prev, 0)}</td>
              <td>{nonHazWaste.reduce((acc, curr) => acc + curr.curr, 0)}</td>
            </tr>
          </tbody>
        </table>

        <p>
          To reduce the disposal of single-use plastics, we have partnered with
          a licensed third-party waste handler to collect and manage plastic
          waste at its end of life. This collaboration ensures adherence to
          pollution control board guidelines and complies with extended producer
          responsibility (EPR) regulations.
        </p>
      </div>

      <div>
        <h2>Emissions Management</h2>
        <h3>Scope 1 GHG Emissions</h3>

        <p>
          We systematically monitor and report on the emissions from direct fuel
          sources, such as HSD, furnace oil, petrol, LDO, and coal, used in our
          operations. Over the past two years, our Scope 1 emissions have been
          trending downward, showing reductions in both the total amount of
          emissions and the emissions intensity per unit of output.
        </p>

        <h4>Scope 1 Emissions (tCO2)</h4>

        <div className="w-100 d-flex justify-content-between">
          <img style={{ width: "50%" }} src={scope1} alt="" />
        </div>

        <h3 style={{ color: "green" }}>Scope 2 GHG Emissions</h3>

        <h2 style={{ color: "green" }}>Free Text</h2>
      </div>

      <h3 style={{ color: "green" }}>Other Air Emissions</h3>

      <p>
        We track emissions of air pollutants, including sulfur oxides (SOx),
        nitrogen oxides (NOx), and particulate matter from our operations. We
        consistently keep these emissions below the limits set by central and
        state pollution control boards. Additionally, we are actively pursuing
        measures to further reduce the intensity of air pollutant emissions and
        ensure full compliance with applicable environmental standards and
        regulations.
      </p>
    </div>
  );
};

export default InitialContent;
