import ColumnChart from "../charts/columnChart";
import DonutChart from "../charts/donutChart";
// import avatar from "../../assets/images/users/avatar-1.jpg";
import { useMemo, useState } from "react";
import useStore from "../../hooks/useStore";
import { STATIC_GROUPS, TARGET_LABEL } from "../../constants/constants";
import { observer } from "mobx-react";
import { Accordion } from "react-bootstrap";
import WaterAccordion from "./waterAccordion";
import ShowPerformance from "../showPerformance";
import { toJS } from "mobx";
import { calculateMaxWithIncrease } from "../../utils/helpers";
import CustomSpinner from "../spinner/Spinner";

function DashboardWater({ year }) {
  const {
    dashboardStore: { dashboardDetails },
    companyStore: { permissibleLimit, companyData },
  } = useStore();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const columnChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.water;
    const result = ["A", "B", "C"].map((group) => {
      return {
        name: STATIC_GROUPS.water[group],
        data: [year - 1, year]?.map(
          (year) =>
            tempData?.[year]?.reduce((acc, curr) => ({ ...acc, ...curr }), {})[
              group
            ]?.prevValue || 0
        ),
        target: [year - 1, year]?.map(
          (year) =>
            tempData?.[year]?.reduce((acc, curr) => ({ ...acc, ...curr }), {})[
              group
            ]?.target || 0
        ),
      };
    });
    return result;
  }, [dashboardDetails?.charts?.water, year]);

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };
  const assignedKpi = toJS(companyData)?.assignKpi?.find(
    (e) => e.kpiIdAssignKpi.name === "Water"
  );

  const limits = permissibleLimit?.filter(
    ({ kpiId, subCatId, catId, year: tempYear }) =>
      kpiId === assignedKpi?.kpiId &&
      catId === assignedKpi?.catId &&
      subCatId === assignedKpi?.subCatId &&
      tempYear == year
  );
  const doughNutChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.water?.[year];
    const result = ["A", "B", "C"].map(
      (group) =>
        tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.[group]
          ?.prevValue || 0
    );
    const labels = ["A", "B", "C"].map((group) => STATIC_GROUPS.water[group]);
    return { data: result || [], labels };
  }, [dashboardDetails?.charts?.water, year]);
  let maxLimitValue;

  if (limits && limits.length > 0) {
    maxLimitValue = Math.max(0, ...limits.map((ele) => ele.value));
  } else {
    const allValues = ["A", "B", "C"].flatMap((group) =>
      [year - 1, year].map(
        (year) =>
          dashboardDetails?.charts?.water?.[year]?.reduce(
            (acc, curr) => ({ ...acc, ...curr }),
            {}
          )[group]?.prevValue || 0
      )
    );
    maxLimitValue = Math.max(0, ...allValues);
  }

  const maxValueWithIncrease = calculateMaxWithIncrease(
    { max: maxLimitValue },
    "max",
    "Inc"
  );

  return (
    <div className="row h-100 ">
      <div className="col-xl-12">
        <div className="card h-100 dashboard-card ">
          <div className="card-body ">
            <div className="d-flex flex-wrap align-items-start">
              <h5 className="mb-3 text-muted"> Water Report</h5>
              <div className="ms-auto">
                <div className="toolbar d-flex flex-wrap gap-2 ">
                  {" "}
                  <div className="">
                    <p className="text-muted mb-1">Unit</p>
                    <h5 className="unit-value">
                      {dashboardDetails?.charts?.water?.unit}
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            {dashboardDetails?.charts ? (
              <div>
                <ColumnChart
                  max={maxValueWithIncrease}
                  data={
                    columnChartData?.map(({ data, name }) => ({
                      data,
                      name,
                    })) || []
                  }
                  unit={dashboardDetails?.charts?.water?.unit || "KL"}
                  categories={[
                    `FY${(year - 1).toString().slice(2)}`,
                    `FY${year.toString().slice(2)}`,
                  ]}
                  yaxis={
                    limits?.map((ele) => ({
                      y: ele.value || 0, // Target value
                      borderColor: "#FF4560",
                      label: {
                        borderColor: "#FF4560",
                        style: {
                          color: "#fff",
                          background: "#FF4560",
                        },
                        text: `${TARGET_LABEL.water[ele.group]}: ${ele.value} ${
                          ele.unitIdMaster.short_name
                        }`,
                      },
                    })) || []
                  }
                />
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-center h-75">
                <CustomSpinner />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="col-md-12 h-100 mt-4  ">
        {/* achievements and target  */}
        <div className="row">
          <div className="col-md-6 mb-4">
            <ShowPerformance label="water" />
          </div>

          {/* donut chart  */}
          <div className="col-md-6 mb-4 ">
            <div className="card mb-0 h-100 dashboard-donut-card ">
              <div className="card-body ">
                <div className="d-flex flex-wrap align-items-start">
                  <h5 className="mb-3 text-muted donut-heading">
                    Water Overall Analysis
                  </h5>
                  <div className="ms-auto">
                    <div className="toolbar d-flex flex-wrap gap-2 ">
                      <div className="">
                        <p className="text-muted mb-1">Unit</p>
                        <h6 className="unit-value">
                          {dashboardDetails?.charts?.water?.unit || "KL"}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                {dashboardDetails?.charts ? (
                  <div className="donut">
                    <DonutChart
                      data={doughNutChartData.data || []}
                      labels={doughNutChartData.labels || []}
                    />
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-center h-75">
                    <CustomSpinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Accordion
        className="dashboard-chart-accordion"
        onSelect={handleAccordionToggle}
      >
        <Accordion.Item key={`waste-accordian`} eventKey={0}>
          <Accordion.Header>
            {" "}
            {isAccordionOpen ? "See less" : "See more"}{" "}
          </Accordion.Header>
          <Accordion.Body>
            <WaterAccordion year={year} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default observer(DashboardWater);
